<template>
    <section class="content">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    Filter Pencarian
                </div>
                <div class="card-body row">
                    <!-- <div class="col-md-4 col-xs-12 form-group">
                        <input type="hidden" name="row_id" value="" />
                        <label class="control-label">Bulan</label>
                        <datepicker
                        placeholder="Bulan"
                        v-model="form.f_bulan"
                        :options="{format:'mm/yyyy', startView: 'months', minViewMode: 'months'}" 
                        :autoclose=true
                        required
                        />
                    </div> -->
                    <div class="col-md-4 col-xs-12 form-group">
                    </div>
                    <div class="col-md-4 col-xs-12 form-group">
                    </div>
                    <div class="col-md-4 col-xs-12 form-group">
                        <label class="control-label">Program</label>
                        <select v-model="form.program_id" class="form-control program">
                        <option v-for="program in optProgram" v-bind:value="program.id">{{ program.label }}</option>
                        <option value="0">SEMUA</option>
                        </select>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
                </div>
            </div>
        </div>
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>NAMA SISWA</th>
                    <th>Cabang</th>
                    <th>Program</th>
                    <th>JK</th>
                    <th>ASAL SEKOLAH</th>
                    <th>TAHUN AJARAN</th>
                    <th>TINDAKAN</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="row">
                                <div class="col-md-8 form-group">
                                    <input type="hidden" name="row_id" value="" />
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Tanggal</label>
                                    <datepicker v-model="form.tanggal" format='DD-MM-YYYY'/>
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Nilai Try Out</label>
                                    <textarea class="form-control" v-model="form.nilai_tryout" ></textarea>
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Nilai Twk</label>
                                    <textarea class="form-control" v-model="form.nilai_twk" ></textarea>
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Nilai TIU</label>
                                    <textarea class="form-control" v-model="form.nilai_tiu" ></textarea>
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Nilai TKP</label>
                                    <textarea class="form-control" v-model="form.nilai_tkp" ></textarea>
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Keterangan</label>
                                    <textarea class="form-control" v-model="form.keterangan" ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import datepicker from '@/components/Datepicker';
import timepicker from '@/components/Timepicker';

export default {
    name: 'SiswaMonitoring',
    components: {
        vSelect,
        timepicker,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            groups: [],
            optJadwal: [],
            optProgram: [],
            formTitle: 'Tambah',
            form: {
                id: '',
                jadwal_id: '',
                tanggal: '',
                waktu_start: '',
                waktu_end: '',
                materi: '',
                soal: '',
                keterangan: '',
                catatan_admin: '',
                program_id: '',
            }
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)

        authFetch('/administrasi/penilaian_evaluasi/jadwal')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optJadwal = js.data;
                console.log(js.data);
            });
        
        authFetch('/akademik/pembagian_kelas/program')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optProgram = js.data;
            });
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }

            if (e.target.closest("button")) {
                if (e.target.dataset.action == "view") {
                    this.$router.push("/perkembangan-siswa/" + e.target.dataset.id);
                }
            }
        },
        search: function() {
            this.table.api().ajax.reload();
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/administrasi/penilaian_evaluasi';
            if (this.method == 'PUT') urlSubmit = '/administrasi/penilaian_evaluasi/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            title: "",
            roles: ["read"],
            ajax: "/akademik/siswa_monitoring",
            selectedRowClass: "",
            columns: [
                { data: "nama_siswa" },
                { data: "branch" },
                { data: "program" },
                { data: "gender",
                render: function (data, type, row, meta) {
                    let jk = '';
                    if (row.gender == "L") jk = 'Pria';
                    else if (row.gender == "P") jk = 'Wanita';
                    else jk = '-';

                    return jk;
                }},
                { data: "asal_sekolah" },
                { data: "tahun_ajaran" },
                { data: "action",
                render: function (data, type, row, meta) {
                    return `<div class="btn-group"><button type="button" class="btn btn-sm btn-outline-info" data-action="view" data-id="`+row.id+`"><i class="fas fa-eye"></i> Detail</button></div>`;
                }, },
            ],
            filter: true,
            filterBy: [0, 5],
            rowCallback: function(row, data) {

            },
            paramData: function ( d ) {
                d.program_id = self.form.program_id;
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Jurnal Guru';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Jurnal Guru';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/administrasi/penilaian_evaluasi/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
        });
    }
}
</script>
<style scoped>
th {
  text-transform: uppercase;
}
</style>